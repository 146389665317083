import clsx from "clsx";
import React from "react";
import { useTheme } from "../../hooks";
import { CategoryResponses, IconThemeType } from "../../types";
import { IconHolder, IconStyled } from "./IconStyles";

export type IconProps = {
  /** name of the icon to display */
  iconName: string;
  /** Category of the icon */
  iconCategory?: CategoryResponses;
  /** class name to pass down */
  className?: string;
  /** onclick functionality for icon */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /** if the icon is disabled */
  disabled?: boolean;
  /** overrideable styles */
  styles?: IconThemeType;
};

const Icon: React.FC<React.PropsWithChildren<IconProps>> = ({
  iconName,
  iconCategory = "fas",
  className,
  onClick,
  disabled = false,
  styles = {},
  ...rest
}) => {
  const { Theme } = useTheme();
  const DefaultStyle: IconThemeType = {
    color: Theme.icon["color"],
    size: Theme.icon["size"],
    hoverColor: Theme.icon["hoverColor"],
    disabledColor: Theme.icon["disabledColor"],
    padding: 0,
  };

  const classes = clsx("best-icon", iconCategory, iconName, className);
  const CompStyles: IconThemeType = { ...DefaultStyle, ...styles };

  return (
    <IconHolder styles={CompStyles} onClick={!disabled ? onClick : undefined}>
      <IconStyled className={classes} disabled={disabled} styles={CompStyles} {...rest} />
    </IconHolder>
  );
};

export default Icon;
