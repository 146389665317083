export const properNoun = (arg: string) => arg.charAt(0).toUpperCase() + arg.slice(1).toLowerCase();

export const isEmpty = (arg?: string): boolean => Boolean(!arg || !arg.trim());

export const uniqueStr = (): string => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const capitalizeFirstLetter = (string: string): string =>
  !!string ? string.replace(/^\w/, (c) => c.toUpperCase()) : string;

export const randomString = (): string => Math.random().toString(36).substr(2, 9);

export const isNumber = (value?: any): boolean => !!value && typeof value == "number";

export const isString = (value?: any): boolean => typeof value == "string";

export const isNA = (showNA: boolean, value: any): boolean => showNA && toLower(value) === "n/a";

export const toLower = (str: any): string => {
  if (str) {
    if (isNumber(str)) {
      return str;
    } else {
      return str.toString().toLowerCase();
    }
  } else {
    return str;
  }
};

export const includes = (data: string, test: string): boolean =>
  !!data && !!test && !!isString(data) && toLower(data).includes(test.toString().toLowerCase());

const MoneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const toMoney = (value: number): string => {
  if (value !== null && value !== undefined) {
    return MoneyFormatter.format(value);
  } else {
    return "";
  }
};

export const replaceAll = (string: string, search: string, replace: string): string =>
  string.split(search).join(replace);
