import styled from "styled-components";
import { IconThemeType } from "../../types";

export type IconStylesProps = {
  disabled?: boolean;
  styles: IconThemeType;
};

export const IconStyled = styled.i<IconStylesProps>`
  &&& {
    color: ${(props) =>
      !!props.disabled && !!props.styles.disabledColor ? props.styles.disabledColor : props.styles.color};
    line-height: ${(props) => props.styles.lineHeight};
    font-size: ${(props) => props.styles.size};

    &:hover {
      color: ${(props) =>
        !!props.disabled && !!props.styles.disabledColor ? props.styles.disabledColor : props.styles.hoverColor};
      cursor: pointer;
    }
  }
`;

export const IconHolder = styled.div.attrs(() => ({
  className: "bcr-icon",
}))<IconStylesProps>`
  display: inline-block;
  padding: ${(props) => props.styles.padding};
  margin: ${(props) => props.styles.margin};
`;
