import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Traditional from "../themes/Traditional";
import { ThemeType } from "../types";

type useThemeType = {
  Theme: ThemeType;
};

export const useTheme = (): useThemeType => {
  const Theme: ThemeType = useContext<ThemeType>(ThemeContext as React.Context<ThemeType>);
  if (!!Theme) {
    return { Theme };
  } else {
    return { Theme: Traditional };
  }
};
